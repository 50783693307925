<template>
  <div class="root inner" :style="{'background-image': `url(${require('@/assets/school/596x300.png')})`}">
    <div class="title">物联监测</div>
    <div class="content">
      <div class="main">
        <div v-for="(item, index) in monitor" :key="index" class="rep" v-if='!item.status.factoryDataStatus=="离线"'>

          <div class="rep-ttile">
            <img class="rep-icon" :src="require('@/assets/school/17x17.png')">
            <div style="margin-left: 5px;">{{ item.machineLocation }}</div>
          </div>
          <div v-if="item.status">
            <el-row v-for="(state, index2) in item.status" :key="index2" class="rep-row">
              <el-col :span="8">{{ state.factoryName }}</el-col>
              <el-col :span="8">{{ state.factoryDataStatus==="离线" ? "0.00" : state.factoryData }}{{ state.factoryUnit }}</el-col>
              <el-col :span="8">{{ state.factoryDataStatus }}</el-col>
            </el-row>
          </div>

        </div>
        <!-- <div class="rep" /> -->
        <!-- <div class="rep" /> -->
      </div>
    </div>
  </div>
</template>

<script>
import { iotMonitor } from '@/api/school/school'
export default {
  data() {
    return {
      monitor: []
    }
  },
  methods: {
    init(school) {
      iotMonitor(school).then(res => {
        this.monitor = res.data
      })
    }
  }
}
</script>

<style scoped lang="scss">
@import './BaseStyle.scss';
.root {
  // height: 300px;
  font-size: 12px;
  height: 100%;
}
.content {
  // margin-left: 24px;
  padding: 0 20px;
  overflow: hidden;
  height: 70%;
}
.title {
  margin-bottom: 10px;
}

.main {
  // width: 544px;
  // margin-left: 8px;
  display: flex;
  justify-content: space-evenly;
  height: 100%;
  .rep {
    border: 1px #ffffff solid;
    border-radius: 10px;
    height: 85%;
    width: 162px;
    padding: 5px;
    overflow: hidden;
    .rep-ttile {
      line-height: 17px;
      height: 17px;
      display: flex;
      margin: 5px 0px 5px 10px;
    }
    .rep-icon {
      height: 17px;
      width: 17px;
    }
    .rep-row {
      text-align: center;
      height: 18px;
      line-height: 18px;
      font-weight: 100;
    }
  }
}
</style>
