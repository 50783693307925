<template>
  <div class="root inner" :style="{'background-image': `url(${require('@/assets/school/596x300.png')})`}">
    <div class="title">基本信息</div>
    <div class="content">
      <el-row class="first-row">
        <el-col :span="6">
          <div class="info-item" :style="{'background-image': `url(${require('@/assets/school/qietu-15.png')})`, 'margin-bottom': '10px'}">
            <span class="item-font">餐厅名称</span><span class="item-value">{{ base.canteenName }}</span>
          </div>
          <div class="info-item" :style="{'background-image': `url(${require('@/assets/school/qietu-15.png')})`, 'margin-bottom': '10px'}">
            <span class="item-font">餐厅评级</span><span class="item-value">{{ base.name }}</span>
          </div>
          <div class="info-item" :style="{'background-image': `url(${require('@/assets/school/qietu-15.png')})`}">
            <span class="item-font">员工数量</span><span class="item-value">{{ base.empCount }}</span>
          </div>
        </el-col>
        <el-col :span="6">
          <div class="imgDiv" style="margin: 0px auto;" :style="{'background-image': `url(${require('@/assets/school/114x86.png')})`}">
            <div class="img">
              <img :src="base.licenseImg" width="100px" height="60px">
            </div>
            营业执照
          </div>
        </el-col>
        <el-col :span="5">
          <div class="imgDiv" :style="{'background-image': `url(${require('@/assets/school/114x86.png')})`}">
            <div class="img">
              <img :src="base.businessImg" width="100px" height="60px">
            </div>
            经营许可证
          </div>
        </el-col>
        <el-col :span="6">
          <div class="imgDiv2" :style="{'background-image': `url(${require('@/assets/school/114x114.png')})`}">
            <div class="filletImg">
              <img :src="base.chargePersonPhoto" width="60px" height="60px">
            </div>
            <div class="filletName">食安管理员 {{ base.chargePersonName }}</div>
          </div>
        </el-col>
      </el-row>
      <el-row class="second-row">
        <el-col :span="5">
          <div class="healthCardImg" :style="{'background-image': `url(${require('@/assets/school/qietu-17.png')})`}" />
        </el-col>
        <el-col :span="18">
          <vue-seamless-scroll :data="healthList" :class-option="classOption" class="healthScroll">
            <div class="scroll">
              <div v-for="(item, index) in healthList" :key="index" class="personalImgDiv">
                <div class="p-img">
                  <img :src="item.healthImg" width="84px" height="60px">
                </div>
                <div class="p-img-name" :style="{'background-image': `url(${require('@/assets/school/qietu-18.png')})`}">{{ item.employeeName }}</div>
              </div>
            </div>
          </vue-seamless-scroll>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import { baseInfo, healthCardInfo } from '@/api/school/school'
import vueSeamlessScroll from 'vue-seamless-scroll'
export default {
  components: {
    vueSeamlessScroll
  },
  data() {
    return {
      classOption: {
        direction: 2,
        singleWidth: 99,
        limitMoveNum: 5
      },
      base: {},
      healthList: []
    }
  },
  methods: {
    init(school) {
      baseInfo(school).then(res => {
        this.base = res.data
      })
      healthCardInfo(school).then(res => {
        this.healthList = res.data
      })
    }
  }
}
</script>

<style scoped lang="scss">
@import './BaseStyle.scss';
.root {
  // height: 300px;
  font-size: 12px;
}
.title {
  margin-bottom: 0px;
}
.imgDiv {
  height: 86px;
  width: 114px;
  text-align: center;
  background-size: 100% 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}
.imgDiv2 {
  height: 114px;
  width: 114px;
  text-align: center;
  margin-left: 15px;
  background-size: 100% 100%;
}
.img {
  height: 60px;
  width: 100px;
  margin: 0px auto 0px auto;
  background: #fff;
}
.filletImg {
  height: 60px;
  width: 60px;
  border-radius: 50%;
  margin: 0px auto;
  background-color: #ffffff;
  position: relative;
  top: 25px;
  overflow: hidden;
}
.filletName {
  position: relative;
  top: 36px;
}
.content {
  margin-left: 24px;
}
.first-row {
  height: 114px;
  display: flex;
  align-items: flex-end;
  margin-bottom: 10px;
}
.second-row {
  display: flex;
  align-items: center;
  height: 100px;
  .healthCardImg {
    height: 34px;
    width: 59px;
    background-size: 100% 100%;
    margin: 0px auto;
  }
  .healthScroll {
    width: 100%;
    height: 60px;
    overflow: hidden;
    .scroll {
      display: flex;
    }
    .personalImgDiv {
      height: 60px;
      width: 84px;
      background: #fff;
      margin-right: 15px;
      flex-shrink: 0;
      .p-img {
        height: 60px;
        width: 84px;
      }
      .p-img-name {
        height: 18px;
        margin-top: -18px;
        line-height: 18px;
        text-align: center;
      }
    }
  }
}
.info-item {
  height: 26px;
  width: 150px;
  background-size: 100% 100%;
  line-height: 26px;
  .item-font {
    margin-left: 15px;
    opacity: 0.6;
  }
  .item-value {
    margin-left: 20px;
  }
}

</style>
