<template>
  <div class="root inner" :style="{'background-image': `url(${require('@/assets/school/596x300.png')})`}">
    <div class="title">晨检信息</div>
    <div class="content">
      <el-row :gutter="10" class="m-row">
        <el-col :span="6" class="m-col">
          <div class="info-item" :style="{'background-image': `url(${require('@/assets/school/qietu-15.png')})`, 'margin-bottom': '10px'}">
            <span class="item-font">今日考勤</span><span class="item-value">{{ checkInfo.todayCount }}</span>
          </div>
          <div class="info-item" :style="{'background-image': `url(${require('@/assets/school/qietu-15.png')})`, 'margin-bottom': '10px'}">
            <span class="item-font">晨检人数</span><span class="item-value">{{ checkInfo.checkCount }}</span>
          </div>
          <div class="info-item" :style="{'background-image': `url(${require('@/assets/school/qietu-15.png')})`, 'margin-bottom': '10px'}">
            <span class="item-font">正常上岗</span><span class="item-value">{{ checkInfo.passCount }}</span>
          </div>
          <div class="info-item" :style="{'background-image': `url(${require('@/assets/school/qietu-15.png')})`, 'margin-bottom': '10px'}">
            <span class="item-font">晨检异常</span><span class="item-value">{{ checkInfo.unPassCount }}</span>
          </div>
        </el-col>
        <el-col :span="15" :offset="2" class="m-col">
          <div class="check-list">
            <el-row :gutter="10" class="check-row">
              <el-col :span="6">姓 名</el-col>
              <el-col :span="6">签到时间</el-col>
              <el-col :span="6">晨检时间</el-col>
              <el-col :span="6">晨检信息</el-col>
            </el-row>
            <vue-seamless-scroll :data="checkList" :class-option="classOption" class="scroll">
              <el-row v-for="(item, index) in checkList" :key="index" :gutter="10" class="check-row">
                <el-col :span="6">{{ item.employeeName }}</el-col>
                <el-col :span="6">{{ item.checkDate }}</el-col>
                <el-col :span="6">{{ item.checkTime }}</el-col>
                <el-col :span="6">{{ item.checkStatus }}</el-col>
              </el-row>
            </vue-seamless-scroll>
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import { morningCheckInfo } from '@/api/school/school'
export default {
  data() {
    return {
      classOption: {
        singleHeight: 26 * 5,
        limitMoveNum: 8
      },
      checkInfo: {
        todayCount: '',
        checkCount: '',
        passCount: '',
        unPassCount: ''
      },
      checkList: []
    }
  },
  methods: {
    init(school) {
      morningCheckInfo(school).then(res => {
        this.checkInfo = Object.assign({}, this.checkInfo, res.data.checkInfoVO)
        this.checkList = res.data.checkListVOS
      })
    }
  }
}
</script>

<style scoped lang="scss">
@import './BaseStyle.scss';
.root {
  // height: 300px;
  font-size: 12px;
}
.content {
  margin-left: 24px;
  height: 75%;
  .m-row {
    height: 100%;
  }
  .m-col {
    height: 100%;
  }
}
.info-item {
  height: 26px;
  width: 150px;
  background-size: 100% 100%;
  line-height: 26px;
  .item-font {
    margin-left: 15px;
    opacity: 0.6;
  }
  .item-value {
    margin-left: 20px;
  }
}
.check-list {
  height: 90%;
  border: 4px solid #0e5dfb;
  border-radius: 6px;
  .check-row {
    margin: 0px !important;
    height: 26px;
    line-height: 26px;
    text-align: center;
    border-bottom: 2px solid #0e5dfb;
  }
  .scroll {
    height: calc(100% - 26px);
    overflow: hidden;
  }
}
</style>
