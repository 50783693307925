<template>
  <div class="root inner" :style="{'background-image': `url(${require('@/assets/school/596x580.png')})`}">
    <div class="title">菜谱公式</div>
    <div class="content">
      <el-row :gutter="20" class="content-row">
        <el-col :span="6" :offset="5">早餐</el-col>
        <el-col :span="6">中餐</el-col>
        <el-col :span="6">晚餐</el-col>
      </el-row>
      <div class="scroll">
        <!-- <vue-seamless-scroll :data="publicMenu" :class-option="classOption" class="scroll"> -->
        <el-row v-for="(item, index) in publicMenu" :key="index" :gutter="20" class="content-row">
          <el-col :span="4" :offset="1" :class="{'highlight': item.today === 1}">{{ item.week }}</el-col>
          <el-col :span="6">{{ item.breakfast }}</el-col>
          <el-col :span="6">{{ item.lunch }}</el-col>
          <el-col :span="6">{{ item.dinner }}</el-col>
        </el-row>
      <!-- </vue-seamless-scroll> -->
      </div>
    </div>
  </div>
</template>

<script>
import { publicMenuInfo } from '@/api/school/school'
export default {
  data() {
    return {
      classOption: {
        singleHeight: 52.38 * 7,
        limitMoveNum: 7
      },
      publicMenu: []
    }
  },
  methods: {
    init(school) {
      publicMenuInfo(school).then(res => {
        // 此处需要对数据进行重新排序，让今天的在上面
        const menu = res.data
        const i = new Date().getDay()
        const arr1 = menu.splice(i)
        const arr2 = menu.splice(0, i)
        this.publicMenu = [...arr1, ...arr2]
      })
    }
  }
}
</script>

<style scoped lang="scss">
@import './BaseStyle.scss';
.root {
  height: 100%;
}
.content {
  margin-left: 24px;
  .content-row {
    height: calc(57vh / 8 - 15px );
    line-height: 25px;
    margin-right: 40px !important;
    word-break: break-all;
    display: contents;
  }
  .scroll {
    height: 380px;
    overflow: hidden;
  }
}
.highlight {
  background-color: #0e5dfb;
}

.el-col {
  border: 1px solid transparent;
}
</style>
